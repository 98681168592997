import styled from 'styled-components';

import Button from '~/src/common/components/Button';
import { Icon } from '~/src/common/components/Button/layout';
import { NAV_HEIGHT, media } from '~/src/styles-constants';

export const DeliveryContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  gap: 8px;
  width: 100%;

  border: none;

  height: ${NAV_HEIGHT}px;
  padding: 0 8px;

  ${media.XL`
    height: 32px;
    padding: 0;
  `}
`;

export const DeliveryButtonItem = styled(Button)<{ $isToComplete: boolean }>`
  height: 32px;
  margin: 0 auto;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  background-color: ${({ theme }) => theme.palette.common.DARK_LABEL};
  color: ${({ theme }) => theme.palette.common.WHITE};
  font-weight: 400;

  &&:hover {
    background-color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
    color: ${({ theme }) => theme.palette.common.WHITE};
    border-color: ${({ theme }) => theme.palette.common.PRIMARY};
  }

  ${Icon} {
    color: ${({ theme }) => theme.palette.common.WHITE};
  }

  & > div {
    width: 100%;
    justify-content: flex-start;
  }

  & > div > span:not(:first-child) {
    font-size: 14px;
    line-height: 16px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > div > span:first-child {
    margin-left: 8px !important;
  }

  & > div > span:last-child {
    margin-right: 8px !important;
  }

  ${media.XL<{ $isToComplete: boolean }>`
    width: ${({ $isToComplete }) => ($isToComplete ? '256px' : '167.5px')};
  `}
`;
